<template>
  <div>
    <div class="wrapper">
      <portal to="body-top">
        <h3 class="text-center font-weight-bolder mb-1">
          {{ $t('Fulfillment for Order TO-') }}{{ id }}
        </h3>
      </portal>
      <div class="statusBar">
        <quote-status-bar
          :item="quoteStatusBarItem"
          :quote-status-bar="quoteStatusBarItems"
          class="ml-1"
        />
      </div>
      <div id="printContent">
        <order-exception-status :item="transferOrder" />
        <b-row class="mt-1">
          <b-col cols="6">
            <transfer-dispatch
              class="dispatch"
              :order-item="transferOrder"
            />
          </b-col>
          <b-col cols="6">
            <transfer-return
              :order-item="transferOrder"
            />
          </b-col>
        </b-row>
      </div>
      <div class="mt-1">
        <transfer-items />
      </div>
    </div>
    <div class="d-flex mt-2 pb-1 justify-content-between buttonWrapper">
      <b-button
        v-if="true"
        class="cancelBtn font-medium-1 font-weight-bolder"
        variant="outline-primary"
        @click="$router.push({ name: 'home-warehouse-view' })"
      >
        {{ $t('Back to List') }}
      </b-button>
      <div class="d-flex">
        <b-button
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3 d-flex align-items-center justify-content-center ml-2"
          @click="printContent('printContent')"
        >
          <feather-icon
            icon="LPrintIcon"
            style="margin-right: 10px"
            size="20"
          />
          <span>{{ $t('Print Pick List') }}</span>
        </b-button>
        <b-button
          variant="success"
          class="d-flex  justify-content-center ml-2"
          style="width: 170px; height: 40px;"
          :disabled="!checkTransferOrders"
          @click="submitPick"
        >
          <feather-icon
            icon="LPickIcon"
            style="margin-right: 10px"
            size="20"
          />
          <span>{{ $t('Pick') }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
// import { ValidationObserver } from 'vee-validate'
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import TransferDispatch from '@/views/main/warehouse/view/pick/transfer/components/TransferDispatch.vue'
import TransferItems from '@/views/main/warehouse/view/pick/transfer/components/TransferItems.vue'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import mainConfig from '@/views/main/warehouse/main-order/config'
import { printContent } from '@/views/main/orders/components/helpers'
import OrderExceptionStatus
from '@/views/main/warehouse/main-order/components/order-exception-status/OrderExceptionStatus.vue'
import TransferReturn from '@/views/main/warehouse/view/pick/transfer/components/TransferReturn.vue'
import warehouseConfig from '@/views/main/warehouse/config'

export default {
  name: 'TransferForm',
  components: {
    BCol,
    BRow,
    OrderExceptionStatus,
    BButton,
    TransferItems,
    TransferDispatch,
    TransferReturn,
    QuoteStatusBar,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    inspectedElements() {
      return this.$store.state[this.MODULE_NAME].inspectedElements
    },
    pickedElements() {
      return this.$store.state[this.MODULE_NAME].pickedElements
    },
    transferOrder() {
      return this.$store.state[this.MODULE_NAME].transferOrder
    },
    checkTransferOrders() {
      return this.pickedElements.length === this.transferOrder.order_items.length && this.pickedElements.length === this.inspectedElements.length
    },
    quoteStatusBarItem() {
      return {
        state: this.transferOrder.fulfillment_state,
        status: this.transferOrder.fulfillment_status,
      }
    },
    quoteStatusBarItems() {
      return this.quoteStatusItemsMerge(this.transferOrder.states, false)
    },
  },
  methods: {
    submitPick() {
      const orderId = this.$route.params.id
      this.$store.dispatch(`${this.MODULE_NAME}/postTransferOrder`, {
        order_id: orderId,
        picked_elements: this.pickedElements,
      }).then(() => {
        this.$router.push({ name: 'home-warehouse-pack-order', params: { id: orderId, orderType: this.ORDER_TYPES_KV.TO } })
      }).catch(err => {
        this.errorNotification(this, err)
      })
    },
  },
  setup() {
    const { transferFields, MODULE_NAME } = config()

    const { ORDER_TYPES_KV } = mainConfig()
    const { quoteStatusItemsMerge } = warehouseConfig()

    return {
      transferFields,
      MODULE_NAME,
      quoteStatusItemsMerge,
      ORDER_TYPES_KV,
      printContent,
    }
  },
}
</script>
