<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Transfer Items') }}
      </h2>
      <scan
        :asset-item-serial-numbers="serialNumbers"
        @getValue="handleScannedValue"
      />
      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Order RO-') }}1024
      </h2>

      <l-table-list-collector
        :ref="`lTableRef-${1}`"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :is-searchable.camel="false"
        :has-footer="false"
        :fetched-data="assetItems"
        :row-active="{
          filedKey: 'pick_at',
          classes: 'highlightRow'
        }"
        show-empty-list-title
      >
        <template #head(action)>
          <div
            class="d-flex"
            style="padding-left: 16px"
          >
            <b-form-checkbox class="_custom_checkbox" />
          </div>
        </template>
        <template
          #cell(action)="{data: {item}}"
        >
          <div
            class="d-flex"
          >
            <b-form-checkbox
              class="_custom_checkbox"
              @change="handlePickUp(item)"
            />
          </div>
        </template>
        <template #cell(state)="{data: {item}}">
          <div
            class="text-center"
          >
            <state-of-asset
              :data="item"
              size="30"
              :with-label="false"
              :styles="{ color: '#00B139', width: '20px', height: '20px' }"
            />
          </div>
        </template>
        <template #cell(sku)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.product.sku }}
          </div>
        </template>
        <template #cell(name)="{data: {item}}">
          <div
            class="text-center"
          >
            <div class="d-flex nameCell">
              <span
                v-if="item.icon"
                class="badge badge-secondary border-0"
              >C</span>
              <span>{{ item.product.name }}</span>
            </div>
          </div>
        </template>
        <template #cell(location)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.location.level_name }}
          </div>
        </template>
        <template #cell(asset_id)="{data: {item}}">
          <div
            class="text-center d-flex"
          >
            <feather-icon
              icon="LDangerIcon"
              class="lightIcon border-dotted featherIcon"
              size="30"
            />
            {{ item.asset_id }}
          </div>
        </template>
        <template #cell(picked)="{data: {item}}">
          <div class="text-center">
            <feather-icon
              v-if="item.pick_at"
              icon="LCheckIcon"
              class="lightIcon featherIcon"
              style="color: green"
              size="30"
              @click="handleClick(item)"
            />
          </div>
        </template>

        <template #cell(inspected)="{data: {item}}">
          <div class="text-center">
            <b-form-checkbox
              class="customCheckbox"
              @change="handleInspected(item)"
            />
          </div>
        </template>
      </l-table-list-collector>

      <h2 class="font-weight-bolder font-medium-5 pt-2">
        {{ $t('Permanent Transfer') }}
      </h2>
      <l-table-list-collector
        ref="lTableRef"
        :table-columns="tableColumns"
        :module-name="MODULE_NAME"
        :is-searchable.camel="false"
        :fetched-data="permanentItems"
        show-empty-list-title
        :row-active="{
          filedKey: 'pick_at',
          classes: 'highlightRow'
        }"
      >
        <template #head(action)>
          <div
            class="d-flex"
            style="padding-left: 16px"
          >
            <b-form-checkbox class="_custom_checkbox" />
          </div>
        </template>
        <template
          #cell(action)="{data: {item}}"
        >
          <div
            class="d-flex"
          >
            <b-form-checkbox
              class="_custom_checkbox"
              @change="handlePickUp(item)"
            />
          </div>
        </template>
        <template #cell(state)="{data: {item}}">
          <div
            class="text-center"
          >
            <state-of-asset
              :data="item"
              size="30"
              :with-label="false"
              :styles="{ color: '#00B139', width: '20px', height: '20px' }"
            />
          </div>
        </template>
        <template #cell(sku)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.product.sku }}
          </div>
        </template>
        <template #cell(name)="{data: {item}}">
          <div
            class="text-center"
          >
            <div class="d-flex nameCell">
              <span
                v-if="item.icon"
                class="badge badge-secondary border-0"
              >C</span>
              <span>{{ item.product.name }}</span>
            </div>
          </div>
        </template>
        <template #cell(location)="{data: {item}}">
          <div
            class="text-center"
          >
            {{ item.location.level_name }}
          </div>
        </template>
        <template #cell(asset_id)="{data: {item}}">
          <div
            class="text-center d-flex"
          >
            <feather-icon
              icon="LDangerIcon"
              class="lightIcon border-dotted featherIcon"
              size="30"
            />
            <sub-rental-assets :item="item" />
          </div>
        </template>
        <template #cell(picked)="{data: {item}}">
          <div class="text-center">
            <feather-icon
              v-if="item.pick_at"
              icon="LCheckIcon"
              class="lightIcon featherIcon"
              style="color: green"
              size="30"
              @click="handleClick(item)"
            />
          </div>
        </template>

        <template #cell(inspected)="{data: {item}}">
          <div class="text-center">
            <b-form-checkbox
              class="customCheckbox"
              @change="handleInspected(item)"
            />
          </div>
        </template>
      </l-table-list-collector>
    </div>
  </div>
</template>

<script>
import config from '@/views/main/warehouse/view/pick/transfer/transferConfig'
import LTableListCollector from '@/views/components/LTableListCollector.vue'
import { BFormCheckbox } from 'bootstrap-vue'
import Scan from '@/views/components/Scan/Scan.vue'
import StateOfAsset from '@/views/main/inventory/inventory-view/components/StateOfAsset.vue'
import _ from 'lodash'
import SubRentalAssets from '@/views/main/warehouse/main-order/components/sub-rental-asset/SubRentalAsset.vue'

export default {
  name: 'TransferItems',
  components: {
    StateOfAsset,
    Scan,
    LTableListCollector,
    BFormCheckbox,
    SubRentalAssets,
  },
  data() {
    return {
      scannedAssets: '',
      id: this.$route.params.id,
    }
  },
  computed: {
    assetItems() {
      return _.filter(this.transferOrders.order_items, { to_type: 0 })
    },
    permanentItems() {
      return _.filter(this.transferOrders.order_items, { to_type: 1 })
    },
    inspectedElements() {
      return this.$store.state[this.MODULE_NAME].inspectedElements
    },
    pickedElements() {
      return this.$store.state[this.MODULE_NAME].pickedElements
    },
    transferOrders() {
      return this.$store.state[this.MODULE_NAME].transferOrder
    },
    serialNumbers() {
      return this.transferOrders.order_items.map(item => `${item.inventory_item?.serial_number}_${item.product.sku}`)
    },
  },
  created() {
    // const picked = this.transferOrders.order_items.filter(item => item.pick_at !== null)
    // const inspected = this.transferOrders.order_items.filter(item => item.inventory_item.inspected)
    // // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < inspected.length; i++) {
    //   this.$store.commit(`${this.MODULE_NAME}/ADD_INSPECTED_ELEMENT`, inspected[i].id)
    // }
    // // eslint-disable-next-line no-plusplus
    // for (let i = 0; i < picked.length; i++) {
    //   this.$store.commit(`${this.MODULE_NAME}/ADD_PICKED_ITEM`, picked[i].id)
    // }
  },
  methods: {
    handleInspected(item) {
      if (!this.inspectedElements.includes(item.id)) {
        this.$store.commit(`${this.MODULE_NAME}/ADD_INSPECTED_ELEMENT`, item.id)
      } else {
        this.$store.commit(`${this.MODULE_NAME}/REMOVE_INSPECTED_ELEMENT`, item.id)
      }
    },
    handlePickUp(item) {
      if (!this.pickedElements.includes(item.id)) {
        this.$store.commit(`${this.MODULE_NAME}/ADD_PICKED_ITEM`, item.id)
      } else {
        this.$store.commit(`${this.MODULE_NAME}/REMOVE_PICKED_ITEM`, item.id)
      }
    },
    handleClick(item) {
      item.pick_at = null
      this.$store.commit(`${this.MODULE_NAME}/REMOVE_PICKED_ITEM`, item.id)
    },
    handleScannedValue(value) {
      const val = value.toString().trim()
      Object.values(this.transferOrders.order_items).forEach(item => {
        if ((item.inventory_item && item.inventory_item?.serial_number === val) || item.product.sku === val) {
          item.pick_at = new Date().toISOString()
          this.$store.commit(`${this.MODULE_NAME}/ADD_PICKED_ITEM`, item.id)
        }
      })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()
    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>

<style scoped>

@media print {
  #permanent {
    display: none;
  }
}
.custom-checkbox .custom-control-label::before, .custom-radio .custom-control-label::after{
  width: 190px;
 }

.table-striped{
  display: none;
}

.customCheckbox {
  padding-right: 3rem !important;
  & > label {
    position: relative;
  }
}
.nameCell{
  padding-left: 1.5rem;
  gap: 10px;
  align-items: center;
}

</style>
