export default {
  SET(state, data) {
    state.transferOrder = { ...data }
  },
  ADD_PICKED_ITEM(state, id) {
    const element = state.pickedElements
    if (!element.includes(id)) {
      state.pickedElements.push(id)
    }
  },
  REMOVE_PICKED_ITEM(state, id) {
    state.pickedElements = state.pickedElements.filter(item => item.id !== id)
  },
  ADD_INSPECTED_ELEMENT(state, id) {
    const element = state.inspectedElements
    if (!element.includes(id)) {
      state.inspectedElements.push(id)
    }
  },
  REMOVE_INSPECTED_ELEMENT(state, id) {
    state.inspectedElements = state.inspectedElements.filter(item => item !== id)
  },
  // LIST(state, data) {
  //   state.transferOrderAssets = data
  // },
}
